<!--Main Layout-->
<main>
  <div class="container">
    <!--Grid row-->
    <div class="row py-5">
      <!--Grid column-->
      <div class="col-md-12 mt-5">
        <!-- Section: Contact v.1 -->
        <section class="my-5">

          <!-- Section heading -->
          <h2 class="h1-responsive font-weight-bold text-center my-5">Contact Us</h2>

          <!-- Grid row -->
          <div class="row mb-5">

            <!-- Grid column -->
            <div class="col-lg-7">
              <!-- Excerpt -->
              <p><strong>How can we help you?</strong></p>
              <p>Namfel offers a wide range of services to help companies of all sizes move their businesses forward.
              </p>
              <p><strong>We would be happy to hear from you to find out how we can help.</strong></p>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-2">
                <img class="img-fluid" src="./assets/contact-us.png" alt="contact">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

          </div>
          <!-- Grid row -->

          <div class="row">

            <!--Grid column-->
            <div class="col-lg-7">

              <div class="row text-center">
                <div class="col-md-6">
                  <a mdbBtn floating="true" size="md" gradient="purple" mdbWavesEffect>
                    <mdb-icon fas icon="envelope"></mdb-icon>
                  </a>
                  <p>info@namfel.com</p>
                  <p>support@namfel.com</p>
                </div>
                <div class="col-md-6">
                  <a mdbBtn floating="true" size="md" gradient="purple" mdbWavesEffect>
                    <mdb-icon fas icon="phone"></mdb-icon>
                  </a>
                  <p>
                    <span class="flag-icon flag-icon-ca flag-icon-squared mr-3"></span> +1 416-628-3317
                  </p>
                  <p>
                    <span class="flag-icon flag-icon-us flag-icon-squared mr-3"></span> +1 305-952-1135
                  </p>
                  <p class="mb-md-0">Mon - Fri, 8:00-22:00 EST</p>
                </div>
              </div>
              <div class="row mb-5"></div>
              <div class="row text-center">
                <div class="col-md-6">
                  <a mdbBtn floating="true" size="md" gradient="purple" mdbWavesEffect>
                    <mdb-icon fas icon="map-marker-alt"></mdb-icon>
                  </a>
                  <p>80 SW 8th ST</p>
                  <p>MIAMI FL 33130</p>
                  <p class="mb-md-0">United States of America</p>
                </div>
                <div class="col-md-6">
                  <!--Google map-->
                  <div id="map-container-section" class="z-depth-1-half map-container-section mb-4">
                    <iframe
                      src="https://maps.google.com/maps?q=80+SW+8th+St,+Miami,+FL+33130&t=&z=16&ie=UTF8&iwloc=&output=embed"
                      frameborder="0" style="border:0" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
              <div class="row mb-5"></div>
              <div class="row text-center">
                <div class="col-md-6">
                  <a mdbBtn floating="true" size="md" gradient="purple" mdbWavesEffect>
                    <mdb-icon fas icon="map-marker-alt"></mdb-icon>
                  </a>
                  <p>100 King Street West</p>
                  <p>Toronto ON M5X 1C9</p>
                  <p class="mb-md-0">Canada</p>
                </div>
                <div class="col-md-6">
                  <!--Google map-->
                  <div id="map-container-section" class="z-depth-1-half map-container-section mb-4">
                    <iframe
                      src="https://maps.google.com/maps?q=100+King+Street+West,+Toronto&t=&z=16&ie=UTF8&iwloc=&output=embed"
                      frameborder="0" style="border:0" allowfullscreen></iframe>
                  </div>
                </div>
              </div>

            </div>
            <!--Grid column-->
          </div>

        </section>
        <!-- Section: Contact v.1 -->
      </div>
      <!--Grid column-->
    </div>
    <!--Grid row-->
  </div>
</main>