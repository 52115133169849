import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ContactService {
    constructor(
        private httpClient: HttpClient) { }

    private formatErrors(error: any) {
        return throwError(error.message);
    }

    Send(contactForm: FormData): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/javascript'
            })
        };

        return this.httpClient.post(
            `${environment.contact_api_url}`,
            contactForm,
            httpOptions
        ).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.formatErrors(error);
            }
            ));
    }
}
