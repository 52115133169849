<!-- Footer -->
<footer class="page-footer font-small unique-color-dark mt-0">

  <div style="background-color: #6351ce;">
    <div class="container">

      <!-- Grid row-->
      <div class="row py-4 d-flex align-items-center">

        <!-- Grid column -->
        <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
          <h6 class="mb-0">Get connected with us on social networks!</h6>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 col-lg-7 text-center text-md-right">

          <!-- Facebook -->
          <a class="fb-ic">
            <mdb-icon fab icon="facebook" class="white-text mr-4"></mdb-icon>
          </a>
          <!-- Twitter -->
          <a class="tw-ic">
            <mdb-icon fab icon="twitter" class="white-text mr-4"></mdb-icon>
          </a>
          <!-- Google +-->
          <a class="gplus-ic">
            <mdb-icon fab fab icon="google-plus" class="white-text mr-4"></mdb-icon>
          </a>
          <!--Linkedin -->
          <a class="li-ic">
            <mdb-icon fab icon="linkedin" class="white-text mr-4"></mdb-icon>
          </a>
          <!--Instagram-->
          <a class="ins-ic">
            <mdb-icon fab icon="instagram" class="white-text mr-4"></mdb-icon>
          </a>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row-->

    </div>
  </div>

  <!-- Footer Links -->
  <div class="container text-center text-md-left mt-5">

    <!-- Grid row -->
    <div class="row mt-3">

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Company</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a routerLink="/about">About</a>
        </p>
        <p>
          <a routerLink="/careers">Careers</a>
        </p>
        <p>
          <a routerLink="/privacy-and-policy">Privacy and Policy</a>
        </p>
        <p>
          <a routerLink="/terms-of-service">Terms of Service</a>
        </p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Services</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a routerLink="/services" fragment="blockchain-development">Blockchain Development</a>
        </p>
        <p>
          <a routerLink="/services" fragment="cloud-development">Cloud Development</a>
        </p>
        <p>
          <a routerLink="/services" fragment="api-development">API Development</a>
        </p>
        <p>
          <a routerLink="/services" fragment="custom-software-development">Custom Software Development</a>
        </p>
        <p>
          <a routerLink="/services" fragment="web-development">Web Development</a>
        </p>
        <p>
          <a routerLink="/services" fragment="mobile-app-development">Mobile App Development</a>
        </p>
        <p>
          <a routerLink="/services" fragment="performance-optimization">Performance Optimization</a>
        </p>
        <p>
          <a routerLink="/services" fragment="dedicated-development-teams">Dedicated Development Teams</a>
        </p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-md-0 mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contact</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <mdb-icon fas icon="envelope" class="mr-2"></mdb-icon> info@namfel.com
        </p>
        <p>
          <span class="mr-1"></span>
        </p>
        <p>
          <mdb-icon fas icon="home" class="mr-2"></mdb-icon> Miami, USA
        </p>
        <p>
          <span class="flag-icon flag-icon-us flag-icon-squared mr-3"></span> +1 305-952-1135
        </p>
        <p>
          <span class="mr-1"></span>
        </p>
        <p>
          <mdb-icon fas icon="home" class="mr-2"></mdb-icon> Toronto, Canada
        </p>
        <p>
          <span class="flag-icon flag-icon-ca flag-icon-squared mr-3"></span> +1 416-628-3317
        </p>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">Copyright © 2010
    <a href="https://www.namfel.com/"> NamFel Corp.</a> All rights reserved
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->