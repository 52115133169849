<!--Main Layout-->
<main>
    <div class="container">
        <!--Grid row-->
        <div class="row py-5">
            <!--Grid column-->
            <div class="col-md-12 mt-5">

                <!-- Section: Blog v.1 -->
                <section class="my-5">

                    <!-- Section heading -->
                    <h2 class="h1-responsive font-weight-bold text-center my-5">About Us</h2>

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col-lg-7">
                            <!-- Excerpt -->
                            <p>
                                Welcome to Namfel Corp, where excellence, passion, and flexibility define everything we do. Since our inception in 2010, we have been dedicated to delivering world-class services in IT consulting, software development, testing, and user interface design. Our commitment to innovation and quality has made us a trusted partner for clients across various industries.
                            </p>
                            
                            <p>
                                At Namfel Corp, we work on a diverse range of projects, from simple information systems and websites to complex enterprise architectures. Whether it’s developing desktop or web-enabled applications, or implementing traditional n-tier and service-oriented architectures, we have the expertise and experience to meet your needs. Our comprehensive suite of services ensures that we can support your business at every stage of its digital transformation journey.
                            </p>
                            
                            <p>
                                We pride ourselves on our ability to adapt to the unique requirements of each project. Our team of skilled professionals follows three main principles to achieve our goals: do it on-time, do it within scope, and offer the best service at the most competitive cost. This commitment to excellence has allowed us to build long-lasting relationships with our clients, helping them navigate the complexities of the modern technological landscape.
                            </p>
                            
                            <p>
                                In addition to our core services, we are proud to introduce Falcon Alarm, our new trade name focused on home security and automation. With Falcon Alarm, we provide state-of-the-art home security systems and 24/7 alarm monitoring services, ensuring that your home is both secure and smart. We also offer versatile turnkey security solutions for small and medium-sized businesses, as well as Fortune 100 clients, with advanced alarm monitoring and integrated security systems.
                            </p>
                            
                            <p>
                                Namfel Corp is more than just a technology consulting company. We are your partners in innovation, dedicated to helping you achieve your business goals through cutting-edge solutions and unparalleled service. With over a decade of experience, we have established ourselves as a visionary and reliable partner for world-class brands.
                            </p>

                            <p>
                                Thank you for considering Namfel Corp. We look forward to the opportunity to work with you and help drive your business forward. Let us show you the difference that passion, excellence, and flexibility can make.
                            </p>

                        </div>
                        <!-- Grid column -->

                        <!-- Grid column -->
                        <div class="col-lg-5">

                            <!-- Featured image -->
                            <div class="view overlay rounded z-depth-2">
                                <img class="img-fluid" src="./assets/about-us.png" alt="about">
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>

                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>Values</strong></h3>
                            <!-- Excerpt -->
                            <p>Our company is based on innovation, creativity and
                                knowledge. We are satisfied with what we do and proud to integrate an organization that
                                respects
                                values such as:</p>
                            <ul>
                                <li>Dedication and passion for excellence</li>
                                <li>Integrity and Honesty</li>
                                <li>Creativity and Innovation</li>
                                <li>Collaboration and Respect</li>
                                <li>Diversity</li>
                                <li>Desire to share knowledge, achievements, and experiences</li>
                                <li>Care about our colleagues and their quality of life</li>
                                <li>Talent retention and continuous motivation</li>
                            </ul>

                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>Mission</strong></h3>
                            <!-- Excerpt -->
                            <p>Our mission at Namfel Corp is to empower businesses through innovative technology solutions. We aim to support our clients at every stage of their digital transformation journey by providing comprehensive services that include IT consulting, software development, and state-of-the-art security solutions. With the introduction of our new trade name, Falcon Alarm, we are expanding our mission to include home security and automation. By offering cutting-edge home security systems and 24/7 alarm monitoring services, as well as turnkey security solutions for businesses, we strive to ensure that our clients' homes and enterprises are both secure and smart. We are dedicated to helping our clients achieve their business goals through our expertise, reliability, and commitment to excellence.</p>

                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>Vision</strong></h3>
                            <!-- Excerpt -->
                            <p>Our vision is to be a global leader in technology consulting and innovative solutions, recognized for our excellence, passion, and flexibility. We envision a future where Namfel Corp is synonymous with cutting-edge engineering solutions that help businesses of all sizes navigate the complexities of the modern technological landscape. We aim to expand our reach and impact, continuously evolving to meet the changing needs of our clients. By staying true to our values and mission, we aspire to be the trusted partner for world-class brands, providing unparalleled service and driving success and growth for our clients. With over a decade of experience, we look forward to shaping the future of technology and security, one innovative solution at a time.</p>

                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                </section>
                <!-- Section: Blog v.1 -->

            </div>
            <!--Grid column-->
        </div>
        <!--Grid row-->
    </div>
</main>