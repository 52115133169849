<!--Main Layout-->
<main>
    <div class="container">
        <!--Grid row-->
        <div class="row py-5">
            <!--Grid column-->
            <div class="col-md-12 mt-5">

                <!-- Section: Blog v.1 -->
                <section class="my-5">

                    <!-- Section heading -->
                    <h2 class="h1-responsive font-weight-bold text-center my-5">Terms of service</h2>

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">
                            <!-- Excerpt -->
                            <p>
                                NamFel is a software development company. These terms and conditions govern NamFel Corp.
                                ‘s (“NFC”, “we”, or “our”) relationship with you when you use the NFC
                                website, (“site”) and any and all services available on or through the site or otherwise
                                provided by NFC, including any widget provided by NFC (collectively, the “services”). By
                                using or accessing the site, services or a widget, whether manually or through automated
                                means, you agree to these NFC terms and conditions (“terms”). These terms apply to you
                                if you are a participant, developer or any other user of the site and/or services
                                (collectively, “users” or “you”). If you choose to not accept these terms, we won’t be
                                mad at you but it means you can’t use the NFC site or the services. Since we don’t ever
                                want that to happen, be sure to contact us if you have any questions about anything in
                                the world.

                                However, to be eligible to use the services and access the site, you must: (1) be over
                                the age of majority in the jurisdiction in which you live (i.e. at least 18, and in some
                                cases 19) or, if you are a minor over the age of 13, you may only use the site with the
                                permission and supervision of your parent or legal guardian; (2) be registered with us
                                (to the extent required) and not have been previously restricted, suspended or
                                terminated by us; and (3) not be using another member’s account without her/his
                                permission.

                                Access to the site and services from territories where the site and/or services are
                                illegal is strictly prohibited. Access to site and services are prohibited to citizens
                                and residents of United States of America and the residents of Canada and the
                                Commonwealth of Bahamas. Users are responsible for complying with all local rules, laws,
                                and regulations regarding their use of the site and services.</p>

                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col-xs-0 col-md-3">
                        </div>
                        <!-- Grid column -->

                        <!-- Grid column -->
                        <div class="col-xs-12 col-md-6">

                            <!-- Featured image -->
                            <div class="view overlay rounded z-depth-2">
                                <img class="img-fluid" src="./assets/terms-conditions.jpg" alt="terms conditions">
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>
                        </div>
                        <!-- Grid column -->

                        <!-- Grid column -->
                        <div class="col-xs-0 col-md-3">
                        </div>

                    </div>
                    <!-- Grid row -->


                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>Overview</strong></h3>
                            <!-- Excerpt -->
                            <ol>
                                <li><strong>User Names.</strong> You may need a username and password to use certain
                                    features of the site and
                                    services and you may create certain URLs when you establish a profile, fundraiser,
                                    event, or charity page. By selecting a user name and any particular URL you agree
                                    that
                                    you will not select or use a name or URL: (a) of another person with the intent to
                                    impersonate that person; (b) subject to the rights of any person without
                                    authorization;
                                    (c) in violation of the intellectual property rights of any person; or (d) that NFC,
                                    in
                                    its sole discretion, deems inappropriate or offensive. You hereby expressly permit
                                    NFC
                                    to identify you by your user name (which may be a pseudonym). You acknowledge and
                                    agree
                                    that you shall have no ownership or other property interest in your account, URL
                                    and/or
                                    user name, and you further acknowledge and agree that all rights in and to your user
                                    name and account are and shall forever be owned by and inure to the benefit of NFC.
                                    You
                                    are responsible for maintaining the confidentiality of your password and account,
                                    and
                                    are responsible for all activities (whether by you or by others) that occur under
                                    your
                                    password or account. You will notify NFC immediately of any unauthorized use of your
                                    password or account or any other breach of security. NFC assumes no liability for
                                    any
                                    loss or damage arising from any unauthorized use of your password or account by a
                                    third
                                    party. NFC has the right to reclaim any user names or URLs for any reason.</li>
                                <li><strong>Accurate Information.</strong> You agree to: (a) provide accurate
                                    information as
                                    prompted on the
                                    site or through the services; and (b) maintain and update such information to keep
                                    it
                                    accurate. If you provide any information that is inaccurate, or NFC has reasonable
                                    grounds to suspect that such information is inaccurate, NFC may suspend or terminate
                                    your use of the services and/or the site and/or decline to permit your continued use
                                    of
                                    the site and/or the services and future access to the site and/or the services.</li>
                                <li><strong>Privacy.</strong> In addition to reviewing these terms, you should also read
                                    our
                                    Privacy Policy
                                    to better comprehend how we collect and use your personal information. Your use of
                                    the
                                    site and its services constitutes your agreement to our Privacy Policy.</li>
                                <li><strong>Relationship of NFC.</strong> By using this site you understand and agree
                                    that
                                    NFC shall not be
                                    responsible for any losses or damages incurred as a result of the fundraising
                                    campaigns
                                    or events. In the event of a dispute between users (including but not limited to
                                    fundraisers, donors, beneficiaries, and third parties), you hereby release NFC, its
                                    employees, agents, affiliates, directors, officers, representatives, subcontractors,
                                    advisors and volunteers from all claims, damages and demands that may or may not be
                                    known, suspected or related to such disputes about our service.</li>
                            </ol>
                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>General Terms</strong></h3>
                            <!-- Excerpt -->
                            <ol>
                                <li>
                                    The trademarks, logos, and service marks (“Marks”) displayed on the site are
                                    registered trademarks of their respective owners, are the property of their
                                    respective owners, and/or are protected by U.S. and international trademark laws
                                    and/or common law. Their uses are restricted to programs, events, products or
                                    services that NFC sponsors or with which we are otherwise affiliated. NFC’s
                                    trademarks may not be used for personal financial gain. Use of the Marks is
                                    prohibited without NFC’s express written consent except as permitted by applicable
                                    laws. Nothing contained on the site should be construed as granting, by implication,
                                    estoppel, or otherwise, any license or right to use the Marks without NFC’s express
                                    written consent.
                                    <ol>
                                        <li>
                                            No portion of the site, services or Site Information may be reprinted,
                                            republished, modified, or distributed in any form without NFC’s express
                                            written permission. You may not, and these terms do not give you permission
                                            to, reproduce, reverse engineer, decompile, disassemble, modify, transmit,
                                            sell, distribute, license or create derivative works with respect to the
                                            site, services or any of the Site Information.</li>
                                        <li>
                                            You may not remove any copyright, trademark or other intellectual property
                                            or proprietary notice or legend contained on the site, services, or the Site
                                            Information and you must retain all copyright, trademark, service mark and
                                            other proprietary notices contained on the site, services or in the original
                                            Site Information on any authorized copy you make of the site, services or
                                            the Site Information.</li>
                                        <li>
                                            Use the Widget to offer or promote, or otherwise use the Widget in
                                            association with, any products or services for sale;</li>
                                        <li>
                                            Use the Widget (or any content displayed in connection with or through it)
                                            in any manner that would constitute an endorsement by us of any product,
                                            service, activity or brand contained on your website;</li>
                                        <li>
                                            Place the Widget on any website that includes content that is offensive,
                                            harassing, threatening, abusive, discriminatory, vulgar, pornographic, or
                                            otherwise inappropriate, as determined by us in our sole discretion; or</li>
                                        <li>
                                            Use the Widget in any manner that prevents the end users of your website
                                            from linking directly to the application page of our site.</li>
                                    </ol>
                                </li>
                                <li><strong>You agree that submission of any ideas</strong>, suggestions, documents,
                                    and/or proposals to NFC through its suggestion, feedback,
                                    wiki, forum or similar pages (“Feedback”) is at your own risk and that NFC has no
                                    obligations (including without limitation obligations of confidentiality) with
                                    respect to such Feedback. You represent and warrant that you have all rights
                                    necessary to submit the Feedback. You hereby grant to NFC a fully paid,
                                    royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and fully
                                    sublicensable right and license to use, reproduce, perform, display, distribute,
                                    adapt, modify, re-format, create derivative works of, and otherwise commercially or
                                    non-commercially exploit in any manner, any and all Feedback, and to sublicense the
                                    foregoing rights.
                                    <ol>
                                        <li>
                                            a. Use the service in compliance with all laws, regulations, ordinances,
                                            directives, court orders and this Agreement local and applicable to your use
                                            of the site and services;</li>
                                        <li>
                                            b. Use the service so as not to damage, disable, overburden or impair the
                                            service, our networks or systems or not to interfere with any others’ legal
                                            rights or use or enjoyment of the service; and</li>
                                        <li>
                                            c. Not engage in, or encourage, promote, facilitate or instruct others to
                                            engage in, activities which, in our judgment and discretion: (1) are
                                            illegal; (2) result in the infringement of the intellectual property rights
                                            of others or libel or defamation of another person; or (3) harvest or
                                            otherwise collect information about others (such as e-mail addresses or
                                            other personally identifiable information) without their consent.</li>
                                        <li>
                                            An electronic or physical signature of a person authorized to act on behalf
                                            of the owner of the copyright allegedly infringed;</li>
                                        <li>
                                            A description of the copyrighted work you claim has been infringed,
                                            including a copy of the copyrighted work or the web page address where the
                                            copyrighted work may be found;</li>
                                        <li>
                                            Identification of the location on the site of the material you claim has
                                            been infringed, or the link or reference to another website that contains
                                            the material you claim has been infringed;</li>
                                        <li>
                                            Your name, address, telephone number and email address;</li>
                                        <li>
                                            A statement by you that you have a good faith belief that the disputed use
                                            of the material at issue is not authorized by the copyright owner, the agent
                                            of the copyright owner or the law; and</li>
                                        <li>
                                            A statement by you that the information in this notification is accurate and
                                            a statement, under penalty of perjury, that you are the copyright owner of
                                            the material allegedly infringed or authorized to act on the copyright
                                            owner’s behalf.</li>
                                    </ol>
                                </li>
                                <li><strong>Suspension or Termination of Your Use of the Site.</strong>
                                    These Terms will commence on the
                                    date you accept them (as described above) and remain in full force and effect until
                                    terminated in accordance with this section. Notwithstanding the foregoing, if you
                                    used the site or services prior to the date you accepted the terms, you hereby
                                    acknowledge and agree that the terms commenced on the date you first used the site
                                    or services (whichever is earlier) and will remain in full force and effect while
                                    you use the site or services, unless earlier terminated in accordance with the
                                    terms. NFC has the right to suspend or terminate any services provided to you at any
                                    time with or without reason. You agree that all terminations shall be made in NFC’s
                                    sole discretion and that NFC shall not be liable to you or any third party for any
                                    termination of your account. If you want to terminate any services provided by NFC,
                                    you may do so by notifying NFC at any time, with your notice sent, in writing, to
                                    our address set forth below. Termination of any service includes removal of access
                                    to such service and barring of further use of the service, provided that any
                                    donations made prior to the effective date of termination will continue to be
                                    processed. All provisions of the terms which by their nature should survive, shall
                                    survive termination of services, including without limitation, ownership provisions,
                                    warranty disclaimers, and limitation of liability.</li>
                                <li><strong>No Endorsement of Links to Other Web Sites.</strong>
                                    Any links to other websites are provided as merely a convenience to you. This site
                                    and the services may provide links or references to other websites but NFC has not
                                    reviewed all of these other websites, has no responsibility for the content of such
                                    other websites and shall not be liable for any damages or injury arising from the
                                    content from these other websites. You understand that, except for information,
                                    products or services clearly identified as being supplied by NFC, we do not operate,
                                    control or endorse any information, products or services on the Internet in any way.
                                    NFC does not endorse or make any representations about these other websites, or any
                                    information or other products or materials found on these other websites, or any
                                    results that may be obtained from using these other websites. If you decide to
                                    access any of these other websites linked to this site, you do so entirely at your
                                    own risk.</li>
                            </ol>
                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>Liability</strong></h3>
                            <!-- Excerpt -->
                            <ol>
                                <li><strong>You agree to indemnify,</strong>
                                    defend and hold NFC and its affiliates, if any, officers, directors, employees and
                                    agents, harmless from and against any and all claims, demands, actions, costs,
                                    liabilities, losses and damages of any kind (including attorney’s fees) resulting
                                    from: (a) your use, misuse or abuse of the site, services or the Site Information;
                                    or (b) your breach of any provision of these terms. You will cooperate as fully as
                                    reasonably required in NFC’s defense of any claim. NFC reserves the right, at its
                                    own expense, to assume the exclusive defense and control of any matter otherwise
                                    subject to indemnification by you, and you shall not in any event settle any matter
                                    without NFC’s written consent.</li>
                                <li><strong>YOU ACKNOWLEDGE</strong>
                                    THAT YOU ARE USING THE SITE AND THE SERVICES AT YOUR OWN RISK. THE SITE, THE SITE
                                    INFORMATION AND THE SERVICES ARE PROVIDED “AS IS,” AND TO THE FULL EXTENT PERMITTED
                                    BY APPLICABLE LAW, NFC, ITS AFFILIATES AND SUBSIDIARIES, IF ANY, AND ITS THIRD PARTY
                                    SERVICE PROVIDERS, SUPPLIERS AND VENDORS HEREBY EXPRESSLY DISCLAIM ANY AND ALL
                                    WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF
                                    ACCURACY, COMPLETENESS OR RELIABILITY, TITLE, NONINFRINGEMENT, MERCHANTABILITY OR
                                    FITNESS FOR A PARTICULAR PURPOSE, OR ANY OTHER WARRANTY, CONDITION, GUARANTEE OR
                                    REPRESENTATION, WHETHER ORAL, IN WRITING OR IN ELECTRONIC FORM. NFC, ITS AFFILIATES
                                    AND SUBSIDIARIES, IF ANY, AND ITS THIRD PARTY SERVICE PROVIDERS, SUPPLIERS, AND
                                    VENDORS DO NOT REPRESENT OR WARRANT THAT ACCESS TO THE SITE, THE SITE INFORMATION OR
                                    THE SERVICES WILL BE UNINTERRUPTED OR THAT THERE WILL BE NO FAILURES, ERRORS OR
                                    OMISSIONS, OR LOSS OR SECURITY BREACH OF TRANSMITTED INFORMATION, OR THAT NO VIRUSES
                                    WILL BE TRANSMITTED THROUGH ACCESS TO OR USE OF SITE, SERVICES OR SITE INFORMATION.
                                    NFC ASSUMES NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY OR FAILURE
                                    TO STORE ANY CONTENT (INCLUDING, BUT NOT LIMITED TO, ANY USER GENERATED CONTENT) OR
                                    USER COMMUNICATIONS.</li>
                                <li><strong>LIMITATION ON LIABILITY.</strong>
                                    EXCEPT FOR CONSUMER TRANSACTIONS BY RESIDENTS OF QUEBEC OR AS OTHERWISE PROHIBITED
                                    OR RESTRICTED BY LAW, NFC, ITS AFFILIATES AND ITS SUBSIDIARIES, IF ANY, AND ITS
                                    THIRD PARTY SERVICE PROVIDERS, SUPPLIERS, AND VENDORS SHALL NOT BE LIABLE TO YOU OR
                                    ANY THIRD PARTIES FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
                                    ARISING OUT OF THESE TERMS, ANY DONATIONS THROUGH THE SITE, YOUR ACCESS TO OR
                                    INABILITY TO ACCESS THE SITE OR ITS SERVICES OR THE SITE INFORMATION, INCLUDING
                                    WITHOUT LIMITATION FOR VIRUSES ALLEGED TO HAVE BEEN OBTAINED FROM THE SERVICES, YOUR
                                    USE OF OR RELIANCE ON THE SERVICES, THE SITE INFORMATION OR MATERIALS AVAILABLE
                                    THROUGH THIRD PARTY SITES LINKED TO SITE, REGARDLESS OF THE TYPE OF CLAIM OR THE
                                    NATURE OF THE CAUSE OF ACTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                                    UNDER NO CIRCUMSTANCES WILL NFC BE LIABLE TO YOU FOR MORE THAN FIFTY DOLLARS
                                    ($50.00). YOU AND NFC AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO
                                    THE TERMS, THE SITE OR SERVICE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
                                    ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED. SOME
                                    JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR THE LIMITATION OR
                                    EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                                    EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT
                                    VARY DEPENDING ON WHERE YOU RESIDE.</li>
                                <li><strong>YOU HEREBY AGREE TO RELEASE NFC,</strong>
                                    ITS AFFILIATES AND SUBSIDIARIES, IF ANY, AND THIRD-PARTY SERVICE PROVIDERS,
                                    SUPPLIERS AND VENDORS AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
                                    AND AGENTS FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL)
                                    OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED
                                    AND UNDISCLOSED (COLLECTIVELY, “CLAIMS”), ARISING OUT OF OR IN ANY WAY CONNECTED
                                    WITH YOUR USE OF THIS SITE AND ITS SERVICES OR THE SITE INFORMATION.</li>
                                <li><strong>Security of the Site.</strong>
                                    NFC maintains reasonable safeguards and personnel policies that are designed to
                                    guard the site, the services, our systems and our volunteers’, fundraisers’, donors’
                                    and Charities’ information. For example, for the security of your online visit to
                                    the site, NFC may make use of firewall barriers, encryption techniques and/or
                                    authentication procedures. Unfortunately, no data transmission over the Internet can
                                    be guaranteed to be absolutely secure. As a result, while NFC strives to protect
                                    your information, it cannot ensure or warrant the security of any Content you
                                    transmit to us, and you do so at your own risk. In the event of a breach of the
                                    confidentiality or security of your personal information, NFC will notify you as
                                    necessary so you can take appropriate protective steps. Unless you indicate
                                    otherwise, we may notify you under such circumstances using the email address you
                                    provided to us when you registered with the site.</li>
                            </ol>
                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>Miscellaneous</strong></h3>
                            <!-- Excerpt -->
                            <ol>
                                <li>
                                    <strong>Electronic Communications.</strong> When you visit the site, use the
                                    services or send emails to NFC, you are
                                    communicating with us electronically. You consent to receive communications from NFC
                                    electronically. NFC may communicate with you by email or by posting notices on the
                                    site. You agree that all agreements, notices, disclosures and other communications
                                    that NFC provides to you electronically satisfy any legal requirement that such
                                    communications be in writing.
                                </li>
                                <li><strong>Jurisdiction and Governing Law.</strong> These terms and conditions shall be
                                    governed by the laws of the Commonwealth of Bahamas.
                                    <ol>
                                        <li>
                                            It is the express wish of the parties that the terms and all related
                                            documents have been drawn up in English. C’est law volone expresse des
                                            parties que la presente convention ainsi que les documents qui s’y rattacent
                                            soient rediges en anglais.</li>
                                        <li>
                                            If you are located in the United Kingdom, a third party who is not a party
                                            to the terms has no right under the Contracts (Rights of Third Parties) Act
                                            1999 to enforce any provision of the terms, but this does not affect any
                                            right or remedy of such third party which exists or is available apart from
                                            that Act.</li>
                                        <li>
                                            If you are located in Germany, notwithstanding any limitation in these
                                            terms, NFC is also not liable for acts of simple negligence (unless they
                                            cause injuries to or death of any person), except when they are caused by a
                                            breach of any substantial contractual obligations (vertragswesentliche
                                            Pflichten).</li>
                                    </ol>
                                </li>
                                <li><strong>Conflict with Other Agreements.</strong> These terms are in addition to, and
                                    do not nullify, any other agreement between you and NFC or any other applicable
                                    terms and conditions found on the site</li>
                                <li><strong>Modification of the terms.</strong> In NFC’s sole discretion, we may
                                    unilaterally amend or modify these terms or any
                                    other documents referenced herein at any time by posting on the site. The date of
                                    the most recent revision will appear at the top of this page. If NFC has a working
                                    email contact for you and the changes to the terms are material, NFC may notify you
                                    of such changes by sending you an email to the address you have provided to us. NFC
                                    encourages you to review these terms periodically for any updates or changes. Any
                                    amended or modified terms will be effective upon posting, at the time set forth in
                                    an email notice to you or as otherwise decided in NFC’s sole discretion and as
                                    posted to the site. Continued use of the site constitutes acceptance of any modified
                                    terms and conditions.</li>
                                <li><strong>Additional Terms.</strong> NFC also reserves the right to post, from time to
                                    time, additional rules of usage that apply to specific parts of the site, which may
                                    be posted in the relevant parts of the site, and will be identified clearly and
                                    conspicuously. For example, NFC may post additional rules to participate in a
                                    promotion. Your continued use of the site constitutes your agreement to comply with
                                    these additional rules. Those terms will control in the event of any conflict with
                                    these terms.
                                    <ol>
                                        <li>
                                            In the event that one or more portions of these terms shall, for any reason,
                                            be held to be unenforceable, the remaining portion will remain in full force
                                            and effect.</li>
                                        <li>
                                            The headings used throughout these terms are solely for convenience of
                                            reference and are not to be used as an aid in the interpretation of these
                                            terms.</li>
                                        <li>
                                            If NFC fails to enforce any parts of these terms, it will not be considered
                                            a waiver.</li>
                                        <li>
                                            These terms make up the entire agreement between you and NFC regarding the
                                            site and supersede any prior agreements.</li>
                                        <li>
                                            These terms do not confer any third party beneficiary rights.</li>
                                        <li>
                                            You will not assign or transfer any of your rights or responsibilities under
                                            these terms to anyone without NFC’s express written permission.</li>
                                        <li>
                                            NFC may, at any time, assign our rights or delegate our obligations
                                            hereunder without notice to you in connection with a merger, acquisition, or
                                            sale of assets, or by operation of law or otherwise.</li>
                                        <li>
                                            Nothing in these terms shall prevent NFC from complying with the law.</li>
                                        <li>
                                            NFC shall not be liable for any delay or failure to perform resulting from
                                            causes outside its reasonable control, including, but not limited to, acts
                                            of God, war, terrorism, riots, embargos, acts of civil or military
                                            authorities, fire, floods, accidents, strikes or shortages of transportation
                                            facilities, fuel, energy, labor or materials</li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                </section>
                <!-- Section: Blog v.1 -->

            </div>
            <!--Grid column-->
        </div>
        <!--Grid row-->
    </div>
</main>