<!-- Full Page Intro -->
<div class="introview"
  style="background-image: url('./assets/home-banner.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
  <!-- Mask & flexbox options-->
  <div class="mask rgba-gradient d-flex justify-content-center mt-5">
    <!-- Content -->
    <div class="container">
      <!--Grid row-->
      <div class="row mt-5">
        <!--Grid column-->
        <div class="col-md-10 mb-5 mt-5 white-text text-center text-md-left">
          <h2 class="wow fadeInLeft"></h2>
          <h6 class="mb-3 wow fadeInLeft" data-wow-delay="0.3s">Namfel Corp is an established technology consulting company, serving clients nationally since 2010. From technical solutions to strategic advancements, we help move your business forward with a comprehensive suite of services. Our expertise spans across multiple domains, including IT consulting, innovative technology solutions, home security and automation, as well as commercial security and alarm monitoring services. Providing cutting-edge engineering solutions, we assist Fortune 500 companies and enterprise clients in navigating the complexities of their digital evolution journeys.
            <button mdbBtn type="button" gradient="purple" size="sm" mdbWavesEffect routerLink="/about">
              Read more>></button>
          </h6>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->

      <!--Grid row-->
      <div class="row mt-1">
        <!--Grid column-->
        <div class="col-md-8 mb-5 mt-1 white-text text-center text-md-left">
          <!-- Section: Blog v.3 -->
          <section class="my-5">

            <!-- Section heading -->
            <h4 class="h4-responsive font-weight-bold text-left my-4">Recent posts</h4>

            <!-- Grid row -->
            <div class="row">

              <!-- Grid column -->
              <div class="col-lg-5 col-xl-4">

                <!-- Featured image -->
                <div class="overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                  <img class="img-fluid" src="./assets/digital-transformations.jpg"
                    alt="falcon alarm image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>

              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-lg-7 col-xl-8">

                <!-- Post title -->
                <h6 class="font-weight-bold mb-1"><strong>Transforming Businesses with Technology</strong></h6>
                <!-- Excerpt -->
                <p class="grey-text">At Namfel Corp, our greatest accomplishments are measured by the success of our clients. Through innovative technology solutions, we have empowered businesses across various industries to overcome challenges, streamline operations, and achieve remarkable growth. From enhancing home security with our Falcon Alarm system to driving digital transformation in manufacturing and empowering financial services with AI-driven insights, our expertise has made a significant impact.</p>
                <!-- Post data -->
                <p class="dark-grey-text">11/08/2024</p>

              </div>
              <!-- Grid column -->

            </div>
            <!-- Grid row -->

            <!-- Grid row -->
            <div class="row">

              <!-- Grid column -->
              <div class="col-lg-5 col-xl-4">

                <!-- Featured image -->
                <div class="overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                  <img class="img-fluid" src="./assets/FalconAlarm.png"
                    alt="falcon alarm image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>

              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-lg-7 col-xl-8">

                <!-- Post title -->
                <h6 class="font-weight-bold mb-1"><strong>Enhancing Home Security with Falcon Alarm</strong></h6>
                <!-- Excerpt -->
                <p class="grey-text">We are excited to announce the launch of Falcon Alarm, our new line of business under Namfel Corp. Falcon Alarm offers cutting-edge home security systems and 24/7 alarm monitoring services to keep your home and loved ones safe. Our state-of-the-art technology ensures peace of mind, knowing that your security is in trusted hands.</p>
                <!-- Post data -->
                <p class="dark-grey-text">19/04/2024</p>

              </div>
              <!-- Grid column -->

            </div>
            <!-- Grid row -->

            <!-- Grid row -->
            <div class="row">

              <!-- Grid column -->
              <div class="col-lg-5 col-xl-4">

                <!-- Featured image -->
                <div class="overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                  <img class="img-fluid" src="./assets/AI.jpg"
                    alt="falcon alarm image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>

              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-lg-7 col-xl-8">

                <!-- Post title -->
                <h6 class="font-weight-bold mb-1"><strong>Embracing AI for Greater Efficiency</strong></h6>
                <!-- Excerpt -->
                <p class="grey-text">we are continually seeking ways to enhance our internal operations and deliver even greater value to our clients. Recently, we have begun leveraging the latest advancements in artificial intelligence (AI) technology to streamline our processes and improve efficiency across the board. By integrating AI-driven solutions into our workflow, we are able to automate routine tasks, enhance decision-making capabilities, and optimize resource allocation. This not only allows us to operate more effectively but also ensures that we can provide cutting-edge services to our clients with even greater precision and speed. Our commitment to innovation and excellence drives us to embrace these advanced technologies, reinforcing our position as a leader in the technology consulting and security solutions industry.</p>
                <!-- Post data -->
                <p class="dark-grey-text">15/02/2024</p>

              </div>
              <!-- Grid column -->

            </div>
            <!-- Grid row -->

            <!-- Grid row -->
            <div class="row">

              <!-- Grid column -->
              <div class="col-lg-5 col-xl-4">

                <!-- Featured image -->
                <div class="overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                  <img class="img-fluid" src="./assets/GBC.jpg"
                    alt="falcon alarm image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>

              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-lg-7 col-xl-8">

                <!-- Post title -->
                <h6 class="font-weight-bold mb-1"><strong>Green Button Connect My Data (CMD)</strong></h6>
                <!-- Excerpt -->
                <p class="grey-text">Namfel Corp is actively engaged with companies to implement Green Button Connect My Data® (CMD) standards, ensuring seamless integration and compliance. Our dedicated team of experts works diligently to enhance the overall user experience and enable smarter energy decisions. This collaboration underscores our commitment to innovation and sustainability, as we strive to create a greener future through advanced technology solutions. Together with American Energy Company, we are paving the way for a more energy-efficient and environmentally responsible world.</p>
                <!-- Post data -->
                <p class="dark-grey-text">13/04/2023</p>

              </div>
              <!-- Grid column -->

            </div>
            <!-- Grid row -->

            <!-- Grid row -->
            <div class="row">

              <!-- Grid column -->
              <div class="col-lg-5 col-xl-4">

                <!-- Featured image -->
                <div class="overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                  <img class="img-fluid" src="./assets/shapediver.jpg"
                    alt="shapediver image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>

              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-lg-7 col-xl-8">

                <!-- Post title -->
                <h6 class="font-weight-bold mb-1"><strong>3D Product Configurators</strong></h6>
                <!-- Excerpt -->
                <p class="grey-text">We are excited to announce that our Store 24 eCommerce product is now fully integrated with the ShapeDiver API, enabling seamless 3D rendering using Rhino Grasshopper. This powerful combination significantly enhances the user experience by leveraging the advanced computational design and parametric modeling capabilities of Rhino Grasshopper directly within our eCommerce platform. With this integration, you can now offer dynamic and interactive 3D visualizations for your products, allowing customers to customize and visualize their purchases in real-time with unprecedented accuracy and detail. Experience the future of eCommerce with Store 24 and ShapeDiver API, and bring your product presentations to life like never before.</p>
                <!-- Post data -->
                <p class="dark-grey-text">19/04/2020</p>

              </div>
              <!-- Grid column -->

            </div>
            <!-- Grid row -->

            <hr class="my-1">

            <!-- Grid row -->
            <div class="row">

              <!-- Grid column -->
              <div class="col-lg-5 col-xl-4">

                <!-- Featured image -->
                <div class="overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                  <img class="img-fluid" src="./assets/miami-office.jpg"
                    alt="shapediver image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>

              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-lg-7 col-xl-8">

                <!-- Post title -->
                <h6 class="font-weight-bold mb-3"><strong>Miami Office</strong></h6>
                <!-- Excerpt -->
                <p class="grey-text">We are pleased to announce the opening of our new office at miami brickell in view of the increasing demand of our usa customers and to deliver prompt and speedy service to all our patrons. We thank you for your continued support and business and look forward to your patronage in the future also.</p>
                <!-- Post data -->
                <p class="dark-grey-text">10/03/2020</p>

              </div>
              <!-- Grid column -->

            </div>
            <!-- Grid row -->

            <hr class="my-1">

            <!-- Grid row -->
            <div class="row">

              <!-- Grid column -->
              <div class="col-lg-5 col-xl-4">

                <!-- Featured image -->
                <div class="overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                  <img class="img-fluid" src="./assets/ethereum2.jpg"
                    alt="shapediver image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>

              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-lg-7 col-xl-8">

                <!-- Post title -->
                <h6 class="font-weight-bold mb-3"><strong>Casper</strong></h6>
                <!-- Excerpt -->
                <p class="grey-text">Our Treasury Work asset tokenization engine has adopted Casper. Casper is the name of the Ethereum implementation that will turn Ethereum into a PoS blockchain (aka Ethereum 2.0).</p>
                <!-- Post data -->
                <p class="dark-grey-text">15/11/2019</p>

              </div>
              <!-- Grid column -->

            </div>
            <!-- Grid row -->

            <hr class="my-1">

          </section>
          <!-- Section: Blog v.3 -->
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
    <!-- Content -->
  </div>
  <!-- Mask & flexbox options-->
</div>
<!-- Full Page Intro -->

