<!--Main Layout-->
<main>
  <div class="container">
    <!--Grid row-->
    <div class="row py-5">
      <!--Grid column-->
      <div class="col-md-12 mt-5">
        <!--Section: Magazine v.3-->
        <section class="magazine-section my-5">

          <!-- Section heading -->
          <h2 class="h1-responsive font-weight-bold text-center my-5">Our Services</h2>
          <!-- Section description -->
          <p class="text-center w-responsive mx-auto mb-5">As a software development company, The applications we
            develop are unique, addressing the specific business needs of our clients. Our solutions
            serve as a basis that can be individually adjusted to meet customers needs using new technologies
            and features.

            We have developed a reputation for providing high quality, cost-effective custom applications for B2B, and
            B2C solutions.</p>

          <!-- Grid row -->
          <div class="row mb-2">

            <!-- Grid column -->
            <div id="blockchain-development" class="col-lg-4 col-md-12 mb-lg-0 mb-5">

              <!-- Featured news -->
              <div class="single-news mb-1">

                <!-- Image -->
                <div class="view overlay rounded z-depth-2 mb-4">
                  <img class="img-fluid" src="./assets/blockchain-43.jpg" alt="Blockchain">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>

                <!-- Grid row -->
                <div class="row mb-1">

                  <!-- Grid column -->
                  <div class="col-12">

                    <!-- Badge -->
                    <a>
                      <mdb-badge color="purple">
                        <mdb-icon fas icon="camera" class="pr-2" aria-hidden="true"></mdb-icon>Blockchain
                      </mdb-badge>
                    </a>

                  </div>
                  <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-2">
                    <a class="font-weight-bold">Blockchain Development</a>
                  </div>
                </div>

              </div>
              <!-- Featured news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Digital Identity</a>
                  </div>
                  <a>

                  </a>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Tokenization</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Smart Contract</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Cryptocurrency</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Security Token Offerings (STO)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Initial Exchange Offering (IEO)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Initial Coin Offering (ICO)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-5">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Fundraising</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div id="cloud-development" class="col-lg-4 col-md-12 mb-lg-0 mb-5">

              <!-- Featured news -->
              <div class="single-news mb-1">

                <!-- Image -->
                <div class="view overlay rounded z-depth-2 mb-4">
                  <img class="img-fluid" src="./assets/cloud-43.jpg" alt="Cloud">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>

                <!-- Grid row -->
                <div class="row mb-1">

                  <!-- Grid column -->
                  <div class="col-12">

                    <!-- Badge -->
                    <a>
                      <mdb-badge color="purple">
                        <mdb-icon fas icon="camera" class="pr-2" aria-hidden="true"></mdb-icon>Cloud
                      </mdb-badge>
                    </a>

                  </div>
                  <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-2">
                    <a class="font-weight-bold">Cloud Development</a>
                  </div>
                </div>

              </div>
              <!-- Featured news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Legacy Application Migration</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Modern Devops CI/CD</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>cloud and on-premises Hybrid</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Software as a Service (SaaS)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Platform as a Service (PaaS)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Internet of Things</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Big Data Analytics</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-5">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Artificial Intelligence (AI)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div id="api-development" class="col-lg-4 col-md-12 mb-lg-0 mb-5">

              <!-- Featured news -->
              <div class="single-news mb-1">

                <!-- Image -->
                <div class="view overlay rounded z-depth-2 mb-4">
                  <img class="img-fluid" src="./assets/api-43.jpg" alt="Api">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>

                <!-- Grid row -->
                <div class="row mb-1">

                  <!-- Grid column -->
                  <div class="col-12">

                    <!-- Badge -->
                    <a>
                      <mdb-badge color="purple">
                        <mdb-icon fas icon="camera" class="pr-2" aria-hidden="true"></mdb-icon>Api
                      </mdb-badge>
                    </a>

                  </div>
                  <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-2">
                    <a class="font-weight-bold">API Development</a>
                  </div>
                </div>

              </div>
              <!-- Featured news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Api Management</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Microservices Architecture</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Api Protocol (REST, SOAP)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->
              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Api Security (OAuth, SAML, OpenID)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->
              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Authentication and Authorization</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->
              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Api Gateway</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->
              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Api Documentation</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->
              <!-- Small news -->
              <div class="single-news mb-5">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Api Design </a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div id="custom-software-development" class="col-lg-4 col-md-12 mb-lg-0 mb-5">

              <!-- Featured news -->
              <div class="single-news mb-1">

                <!-- Image -->
                <div class="view overlay rounded z-depth-2 mb-4">
                  <img class="img-fluid" src="./assets/software-43.jpg" alt="Web">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>

                <!-- Grid row -->
                <div class="row mb-1">

                  <!-- Grid column -->
                  <div class="col-12">

                    <!-- Badge -->
                    <a>
                      <mdb-badge color="purple">
                        <mdb-icon fas icon="camera" class="pr-2" aria-hidden="true"></mdb-icon>Software
                      </mdb-badge>
                    </a>

                  </div>
                  <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-2">
                    <a class="font-weight-bold">Custom Software Development</a>
                  </div>
                </div>

              </div>
              <!-- Featured news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Desktop Software</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Service Application</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Data Migration</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>ETL</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Reports</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Ecommerce</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Payment solutions</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-5">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Crypto</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div id="web-development" class="col-lg-4 col-md-12 mb-lg-0 mb-5">

              <!-- Featured news -->
              <div class="single-news mb-1">

                <!-- Image -->
                <div class="view overlay rounded z-depth-2 mb-4">
                  <img class="img-fluid" src="./assets/web-43.jpg" alt="Web">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>

                <!-- Grid row -->
                <div class="row mb-1">

                  <!-- Grid column -->
                  <div class="col-12">

                    <!-- Badge -->
                    <a>
                      <mdb-badge color="purple">
                        <mdb-icon fas icon="camera" class="pr-2" aria-hidden="true"></mdb-icon>Web
                      </mdb-badge>
                    </a>

                  </div>
                  <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-2">
                    <a class="font-weight-bold">Web Development</a>
                  </div>
                </div>

              </div>
              <!-- Featured news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Graphic Design</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>UX/UI Design</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Static/Dynamic Website</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Single Page Application (SPA).</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Content Management System (CMS)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Ecommerce</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>SEO</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-5">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Digital Marketing</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div id="mobile-app-development" class="col-lg-4 col-md-12 mb-lg-0 mb-5">

              <!-- Featured news -->
              <div class="single-news mb-1">

                <!-- Image -->
                <div class="view overlay rounded z-depth-2 mb-4">
                  <img class="img-fluid" src="./assets/mobile-43.jpg" alt="Mobile">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>

                <!-- Grid row -->
                <div class="row mb-1">

                  <!-- Grid column -->
                  <div class="col-12">

                    <!-- Badge -->
                    <a>
                      <mdb-badge color="purple">
                        <mdb-icon fas icon="camera" class="pr-2" aria-hidden="true"></mdb-icon>Mobile
                      </mdb-badge>
                    </a>

                  </div>
                  <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-2">
                    <a class="font-weight-bold">Mobile App Development</a>
                  </div>
                </div>

              </div>
              <!-- Featured news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Native iOS (iPhone & iPad)</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Native Android</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Windows Mobile</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Ionic</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>React Native</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Mobile Angular UI</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Adobe PhoneGap</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-5">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Xamarin</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div id="technology" class="col-lg-4 col-md-12 mb-lg-0 mb-0">

              <!-- Featured news -->
              <div class="single-news mb-1">

                <!-- Image -->
                <div class="view overlay rounded z-depth-2 mb-4">
                  <img class="img-fluid" src="./assets/technology-43.jpg" alt="Technology">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>

                <!-- Grid row -->
                <div class="row mb-1">

                  <!-- Grid column -->
                  <div class="col-12">

                    <!-- Badge -->
                    <a>
                      <mdb-badge color="purple">
                        <mdb-icon fas icon="camera" class="pr-2" aria-hidden="true"></mdb-icon>Technology
                      </mdb-badge>
                    </a>

                  </div>
                  <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-2">
                    <a class="font-weight-bold">Software Technology</a>
                  </div>
                </div>

              </div>
              <!-- Featured news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>.NET</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Docker</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Ethereum</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Azure</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Aws</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Google</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>React</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Angular</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div id="performance-optimization"  class="col-lg-4 col-md-12 mb-lg-0 mb-0">

              <!-- Featured news -->
              <div class="single-news mb-1">

                <!-- Image -->
                <div class="view overlay rounded z-depth-2 mb-4">
                  <img class="img-fluid" src="./assets/optimization-43.jpg" alt="Performance Optimization">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>

                <!-- Grid row -->
                <div class="row mb-1">

                  <!-- Grid column -->
                  <div class="col-12">

                    <!-- Badge -->
                    <a>
                      <mdb-badge color="purple">
                        <mdb-icon fas icon="camera" class="pr-2" aria-hidden="true"></mdb-icon>Optimization
                      </mdb-badge>
                    </a>

                  </div>
                  <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-2">
                    <a class="font-weight-bold">Performance Optimization</a>
                  </div>
                </div>

              </div>
              <!-- Featured news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Load Testing</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Code Review</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Caching</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Image Optimization</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Database Optimization</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>HTTP requests reduction</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Compression</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Cloud-based App Monitoring</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div id="dedicated-development-teams"  class="col-lg-4 col-md-6 mb-0">

              <!-- Featured news -->
              <div class="single-news mb-1">

                <!-- Image -->
                <div class="view overlay rounded z-depth-2 mb-4">
                  <img class="img-fluid" src="./assets/team-43.jpg" alt="Dedicated Teams">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>

                <!-- Grid row -->
                <div class="row mb-1">

                  <!-- Grid column -->
                  <div class="col-12">

                    <!-- Badge -->
                    <a>
                      <mdb-badge color="purple">
                        <mdb-icon fas icon="camera" class="pr-2" aria-hidden="true"></mdb-icon>Teams
                      </mdb-badge>
                    </a>

                  </div>
                  <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-2">
                    <a class="font-weight-bold">Dedicated Development Teams</a>
                  </div>
                </div>

              </div>
              <!-- Featured news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Solutions Architect</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Application Architect</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Cloud Architect</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Full Stack Developer</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Frontend Developer</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>UX Developer</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-1">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>API Developer</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

              <!-- Small news -->
              <div class="single-news mb-5">

                <!-- Title -->
                <div class="d-flex justify-content-between">
                  <div class="col-11 text-truncate pl-0 mb-1">
                    <a>Database Developer</a>
                  </div>
                </div>

              </div>
              <!-- Small news -->

            </div>
            <!-- Grid column -->

          </div>
          <!-- Grid row -->

        </section>
        <!--Section: Magazine v.3-->
      </div>
      <!--Grid column-->
    </div>
    <!--Grid row-->
  </div>
</main>