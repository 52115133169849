import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModulesPro, ToastModule, ToastService, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';

import { FooterComponent } from './shared/layout/footer.component';
import { HeaderComponent } from './shared/layout/header.component';
import { ContactComponent } from './contact/contact.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ListErrorsComponent } from './shared/list-errors.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ProjectsComponent } from './projects/projects.component';
import { AboutComponent } from './about/about.component';
import { PapComponent } from './pap/pap.component';
import { TosComponent } from './tos/tos.component';
import { CareersComponent } from './careers/careers.component';

import { ContactService } from './core/services/contact.service';

@NgModule({
  declarations: [AppComponent, FooterComponent,
    HeaderComponent, ContactComponent, PageNotFoundComponent, ListErrorsComponent,
    HomeComponent, ServicesComponent, ProjectsComponent,
    AboutComponent, PapComponent, TosComponent, CareersComponent],
  imports: [
    BrowserModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [MDBSpinningPreloader, ToastService, ContactService, {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6Lfd488SAAAAACfjWqsZmzgW0JqStWuyEe1WSEoe',
    } as RecaptchaSettings,
  }],
  bootstrap: [AppComponent],
})
export class AppModule { }
