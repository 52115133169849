<!-- Full Page Intro -->
<div class="view"
    style="background-image: url('./assets/567999.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
    <!-- Mask & flexbox options-->
    <div class="mask rgba-gradient d-flex justify-content-center mt-5">
        <!-- Content -->
        <div class="container">
            <!--Grid row-->
            <div class="row mt-5">
                <!--Grid column-->
                <div class="col-md-6 mb-5 mt-md-0 mt-5 white-text text-center text-md-left">
                    <hr class="hr-light wow fadeInLeft" data-wow-delay="0.3s">
                    <img src="/assets/404-Background.jpg" class="img-fluid " alt="">
                </div>
                <!--Grid column-->
            </div>
            <!--Grid row-->
        </div>
        <!-- Content -->
    </div>
    <!-- Mask & flexbox options-->
</div>
<!-- Full Page Intro -->