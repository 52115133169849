<!--Main Layout-->
<main>
    <div class="container">
        <!--Grid row-->
        <div class="row py-5">
            <!--Grid column-->
            <div class="col-md-12 mt-5">

                <!-- Section: Blog v.1 -->
                <section class="my-5">

                    <!-- Section heading -->
                    <h2 class="h1-responsive font-weight-bold text-center my-5">Join our team</h2>

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col-lg-7">
                            <!-- Excerpt -->
                            <p>Namfel is a growing fast software development company, established in 2010. The
                                cornerstones of our culture are dedication to the craft of IT development,
                                sustainability and our talented employees.</p>
                            <p>We offer broad opportunities of continuous growth and development to each member of our
                                family. We’re looking for goal-oriented, experienced and forward-minded IT specialists.
                                Here are the list of our current job. Don’t hesitate to get in touch and send your CV to
                                <a href="mailto:jobs@namfel.com?subject=Job%Applicant">jobs@namfel.com</a>
                            </p>

                        </div>
                        <!-- Grid column -->

                        <!-- Grid column -->
                        <div class="col-lg-5">

                            <!-- Featured image -->
                            <div class="view overlay rounded z-depth-2">
                                <img class="img-fluid" src="./assets/careers.jpg" alt="about">
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>

                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!--Grid row-->
                    <div class="row">

                        <!--Grid column-->
                        <div class="col-md mb-4">
                            <mdb-accordion [multiple]="false">
                                <mdb-accordion-item [collapsed]="true">
                                    <mdb-accordion-item-head>Full Stack .NET Developer</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                        <p>Job Description:</p>
                                        <p>We are seeking a talented and experienced FullStack .NET Developer to join our team. The ideal candidate will possess a strong foundation in both front-end and back-end technologies, with a focus on .NET development. You will be responsible for building and maintaining high-quality, scalable applications, collaborating with cross-functional teams, and ensuring the seamless integration of various components.</p>
                                        <p>Key Responsibilities:</p>
                                        <ul>
                                            <li>Design, develop, and maintain high-quality software solutions using .NET technologies</li>
                                            <li>Develop front-end applications using modern JavaScript frameworks such as Angular, React, or Vue.js</li>
                                            <li>Collaborate with product managers, designers, and other developers to deliver high-quality products</li>
                                            <li>Optimize components for maximum performance across a vast array of web-capable devices and browsers</li>
                                            <li>Collaborate with back-end developers and web designers to improve usability</li>
                                            <li>Write clean, maintainable, and efficient code</li>
                                            <li>Ensure the technical feasibility of UI/UX designs</li>
                                            <li>Perform code reviews and provide constructive feedback to team members</li>
                                            <li>Troubleshoot, debug, and optimize existing software</li>
                                        </ul>
                                        <p>Requirements</p>
                                        <ul>
                                            <li>Proven experience as a FullStack .NET Developer or similar role</li>
                                            <li>Strong proficiency in C# and the .NET framework (ASP.NET, .NET Core)</li>
                                            <li>Experience with front-end technologies such as HTML5, CSS3, and JavaScript</li>
                                            <li>Familiarity with JavaScript frameworks/libraries such as Angular, React, or Vue.js</li>
                                            <li>Experience with RESTful APIs and Web Services</li>
                                            <li>Strong understanding of relational databases (SQL Server, MySQL, etc.)</li>
                                            <li>Familiarity with version control systems such as Git</li>
                                            <li>Knowledge of modern software development practices and methodologies (Agile, Scrum, CI/CD)</li>
                                            <li>Excellent problem-solving skills and attention to detail</li>
                                            <li>Strong communication skills, both written and verbal</li>
                                            <li>Bachelor's degree in Computer Science, Engineering, or a related field, or equivalent experience</li>
                                        </ul>
                                        <p>Preferred Qualifications:</p>
                                        <ul>
                                            <li>Experience with cloud platforms such as Azure or AWS</li>
                                            <li>Familiarity with microservices architecture</li>
                                            <li>Experience with containerization tools such as Docker and Kubernetes</li>
                                            <li>Knowledge of automated testing frameworks and tools</li>
                                            <li>Understanding of DevOps practices</li>
                                        </ul>
                                        <p>
                                            NOTE:</p>
                                        <ul>
                                            <li>Please send resume to jobs@namfel.com after reviewing the
                                                job description above</li>

                                        </ul>

                                    </mdb-accordion-item-body>
                                </mdb-accordion-item>
                                <mdb-accordion-item [collapsed]="true">
                                    <mdb-accordion-item-head>DevOps Engineer</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                        <p>Job Description:</p>
                                        <p>We are looking for a skilled and experienced DevOps Engineer to join our growing team. The ideal candidate will be responsible for implementing and managing the infrastructure that supports our software development and deployment processes. You will work closely with software developers, system operators, and other IT staff members to manage code releases and ensure efficient and reliable software delivery.</p>
                                        <p>Key Responsibilities:</p>
                                        <ul>
                                            <li>Design, implement, and manage CI/CD pipelines to automate the software development and deployment process</li>
                                            <li>Monitor and maintain the health, performance, and security of our infrastructure</li>
                                            <li>Collaborate with development teams to ensure smooth and reliable operation of software and systems for fulfilling business objectives and processes</li>
                                            <li>Implement and manage infrastructure as code (IaC) using tools such as Terraform, CloudFormation, or Ansible</li>
                                            <li>Manage containerization and orchestration using tools such as Docker and Kubernetes</li>
                                            <li>Conduct root cause analysis on production issues and implement solutions to prevent future occurrences</li>
                                            <li>Develop and maintain scripts for automation of operational tasks</li>
                                            <li>Ensure system security through the implementation of best practices and regular audits</li>
                                            <li>Stay up-to-date with the latest industry trends and technologies to continuously improve our infrastructure</li>
                                        </ul>
                                        <p>Requirements</p>
                                        <ul>
                                            <li>Proven experience as a DevOps Engineer or in a similar role</li>
                                            <li>Strong proficiency with cloud platforms such as AWS, Azure, or Google Cloud</li>
                                            <li>Experience with CI/CD tools such as Jenkins, GitLab CI, or CircleCI</li>
                                            <li>Proficiency with configuration management tools such as Ansible, Puppet, or Chef</li>
                                            <li>Experience with containerization and orchestration tools such as Docker and Kubernetes</li>
                                            <li>Strong scripting skills in languages such as Bash, Python, or PowerShell</li>
                                            <li>Familiarity with infrastructure as code (IaC) tools such as Terraform or CloudFormation</li>
                                            <li>Experience with monitoring and logging tools such as Prometheus, Grafana, ELK stack, or Splunk</li>
                                            <li>Strong understanding of networking, security, and performance optimization</li>
                                            <li>Excellent problem-solving skills and attention to detail</li>
                                            <li>Strong communication skills, both written and verbal</li>
                                            <li>Bachelor's degree in Computer Science, Engineering, or a related field, or equivalent experience</li>
                                        </ul>
                                        <p>Preferred Qualifications:</p>
                                        <ul>
                                            <li>Experience with serverless architectures</li>
                                            <li>Familiarity with GitOps practices</li>
                                            <li>Understanding of compliance and security best practices (e.g., GDPR, HIPAA)</li>
                                            <li>Knowledge of agile and lean principles</li>
                                        </ul>
                                        <p>
                                            NOTE:</p>
                                        <ul>
                                            <li>Please send resume to jobs@namfel.com after reviewing the
                                                job description above</li>

                                        </ul>

                                    </mdb-accordion-item-body>
                                </mdb-accordion-item>
                                <mdb-accordion-item [collapsed]="true">
                                    <mdb-accordion-item-head>Web Designer</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                        <p>Job Description:</p>
                                        <p>We are seeking a creative and talented Web Designer to join our team. The ideal candidate will have a strong design background, a keen eye for detail, and a passion for creating visually appealing and user-friendly web experiences. You will work closely with our development and marketing teams to design and implement websites that effectively communicate our brand and engage our users.</p>
                                        <p>Key Responsibilities:</p>
                                        <ul>
                                            <li>Design and implement visually appealing and user-friendly websites</li>
                                            <li>Create wireframes, mockups, and prototypes to effectively communicate design ideas</li>
                                            <li>Collaborate with developers to ensure the feasibility of designs and smooth implementation</li>
                                            <li>Stay up-to-date with the latest design trends, tools, and technologies</li>
                                            <li>Conduct user research and testing to gather feedback and improve designs</li>
                                            <li>Ensure designs are optimized for a wide range of devices and interfaces</li>
                                            <li>Maintain brand consistency throughout all design projects</li>
                                            <li>Create and manage design assets, such as icons, graphics, and images</li>
                                            <li>Work with marketing and content teams to ensure websites effectively communicate our brand and messaging</li>
                                        </ul>
                                        <p>Requirements</p>
                                        <ul>
                                            <li>Proven experience as a Web Designer or in a similar role</li>
                                            <li>Strong portfolio showcasing your web design work</li>
                                            <li>Proficiency in design tools such as Adobe Creative Suite (Photoshop, Illustrator, XD), Sketch, or Figma</li>
                                            <li>Strong understanding of web design principles, including layout, typography, color theory, and user experience (UX)</li>
                                            <li>Experience with responsive and adaptive design</li>
                                            <li>Basic knowledge of HTML, CSS, and JavaScript is a plus</li>
                                            <li>Excellent problem-solving skills and attention to detail</li>
                                            <li>Strong communication and collaboration skills</li>
                                            <li>Ability to manage multiple projects and meet deadlines</li>
                                            <li>Bachelor's degree in Web Design, Graphic Design, or a related field, or equivalent experience</li>
                                        </ul>
                                        <p>Preferred Qualifications:</p>
                                        <ul>
                                            <li>Experience with content management systems (CMS) such as WordPress, Joomla, or Drupal</li>
                                            <li>Familiarity with front-end frameworks such as Bootstrap or Foundation</li>
                                            <li>Knowledge of SEO best practices</li>
                                            <li>Understanding of accessibility standards and guidelines</li>
                                        </ul>
                                        <p>
                                            NOTE:</p>
                                        <ul>
                                            <li>Please send resume to jobs@namfel.com after reviewing the
                                                job description above</li>

                                        </ul>

                                    </mdb-accordion-item-body>
                                </mdb-accordion-item>
                                <mdb-accordion-item [collapsed]="true">
                                    <mdb-accordion-item-head>Cloud Architect</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                        <p>Job Description:</p>
                                        <p>We are seeking an experienced and visionary Cloud Architect to join our team. The ideal candidate will be responsible for designing, implementing, and managing our cloud infrastructure. You will work closely with various teams to ensure that our cloud solutions are scalable, secure, and aligned with business objectives. This role requires a deep understanding of cloud architecture and best practices, as well as the ability to lead and mentor other team members.</p>
                                        <p>Key Responsibilities:</p>
                                        <ul>
                                            <li>Design and implement scalable, secure, and reliable cloud architecture solutions</li>
                                            <li>Collaborate with development, operations, and security teams to ensure cloud solutions meet business requirements</li>
                                            <li>Develop and maintain cloud strategy, standards, and best practices</li>
                                            <li>Evaluate and recommend cloud services and solutions to optimize performance, cost, and security</li>
                                            <li>Conduct architecture reviews, identify potential risks, and implement mitigation strategies</li>
                                            <li>Manage and optimize cloud infrastructure for performance, cost, and security</li>
                                            <li>Provide technical leadership and guidance to development and operations teams</li>
                                            <li>Stay current with emerging cloud technologies and industry trends</li>
                                            <li>Develop and maintain documentation for cloud architecture, processes, and procedures</li>
                                            <li>Ensure compliance with relevant regulations and standards</li>
                                        </ul>
                                        <p>Requirements</p>
                                        <ul>
                                            <li>Proven experience as a Cloud Architect or in a similar role</li>
                                            <li>Strong proficiency with cloud platforms such as AWS, Azure, or Google Cloud</li>
                                            <li>Deep understanding of cloud architecture principles and best practices</li>
                                            <li>Experience with infrastructure as code (IaC) tools such as Terraform, CloudFormation, or ARM templates</li>
                                            <li>Proficiency with containerization and orchestration tools such as Docker and Kubernetes</li>
                                            <li>Strong understanding of networking, security, and performance optimization in cloud environments</li>
                                            <li>Experience with CI/CD pipelines and DevOps practices</li>
                                            <li>Strong scripting skills in languages such as Python, Bash, or PowerShell</li>
                                            <li>Excellent problem-solving skills and attention to detail</li>
                                            <li>Strong communication and leadership skills</li>
                                            <li>Bachelor's degree in Computer Science, Engineering, or a related field, or equivalent experience</li>
                                        </ul>
                                        <p>Preferred Qualifications:</p>
                                        <ul>
                                            <li>Cloud architecture certifications (e.g., AWS Certified Solutions Architect, Azure Solutions Architect, Google Cloud Professional Cloud Architect)</li>
                                            <li>Experience with serverless architectures</li>
                                            <li>Familiarity with microservices architecture</li>
                                            <li>Knowledge of compliance and security best practices (e.g., GDPR, HIPAA)</li>
                                            <li>Experience with monitoring and logging tools such as Prometheus, Grafana, ELK stack, or Splunk</li>
                                        </ul>
                                        <p>
                                            NOTE:</p>
                                        <ul>
                                            <li>Please send resume to jobs@namfel.com after reviewing the
                                                job description above</li>

                                        </ul>

                                    </mdb-accordion-item-body>
                                </mdb-accordion-item>
                                <mdb-accordion-item [collapsed]="true">
                                    <mdb-accordion-item-head>.NET Developer</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                        <p>Job Description:</p>
                                        <p>We are looking for a talented and motivated .NET Developer to join our development team. The ideal candidate will be responsible for developing and maintaining high-quality software applications using the .NET framework. You will work closely with other developers, product managers, and stakeholders to deliver scalable and robust solutions that meet business requirements.</p>
                                        <p>Key Responsibilities:</p>
                                        <ul>
                                            <li>Develop, test, and maintain software applications using the .NET framework (ASP.NET, .NET Core)</li>
                                            <li>Collaborate with cross-functional teams to define, design, and ship new features</li>
                                            <li>Write clean, maintainable, and efficient code</li>
                                            <li>Ensure the performance, quality, and responsiveness of applications</li>
                                            <li>Identify and resolve software defects and issues in a timely manner</li>
                                            <li>Participate in code reviews and provide constructive feedback</li>
                                            <li>Stay up-to-date with the latest industry trends and technologies</li>
                                            <li>Contribute to the continuous improvement of development processes and methodologies</li>
                                            <li>Ensure the technical feasibility of UI/UX designs</li>
                                        </ul>
                                        <p>Requirements</p>
                                        <ul>
                                            <li>Proven experience as a .NET Developer or in a similar role</li>
                                            <li>Strong proficiency in C# and the .NET framework (ASP.NET, .NET Core)</li>
                                            <li>Experience with front-end technologies such as HTML5, CSS3, and JavaScript</li>
                                            <li>Familiarity with JavaScript frameworks/libraries such as Angular, React, or Vue.js is a plus</li>
                                            <li>Experience with RESTful APIs and web services</li>
                                            <li>Strong understanding of relational databases (SQL Server, MySQL, etc.)</li>
                                            <li>Familiarity with version control systems such as Git</li>
                                            <li>Knowledge of software development best practices and methodologies (Agile, Scrum, CI/CD)</li>
                                            <li>Excellent problem-solving skills and attention to detail</li>
                                            <li>Strong communication skills, both written and verbal</li>
                                            <li>Bachelor's degree in Computer Science, Engineering, or a related field, or equivalent experience</li>
                                        </ul>
                                        <p>Preferred Qualifications:</p>
                                        <ul>
                                            <li>Experience with cloud platforms such as Azure or AWS</li>
                                            <li>Familiarity with microservices architecture</li>
                                            <li>Knowledge of containerization tools such as Docker</li>
                                            <li>Experience with automated testing frameworks and tools</li>
                                            <li>Understanding of DevOps practices</li>
                                        </ul>
                                        <p>
                                            NOTE:</p>
                                        <ul>
                                            <li>Please send resume to jobs@namfel.com after reviewing the
                                                job description above</li>

                                        </ul>

                                    </mdb-accordion-item-body>
                                </mdb-accordion-item>
                                <mdb-accordion-item [collapsed]="true">
                                    <mdb-accordion-item-head>React Developer</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                        <p>Job Description:</p>
                                        <p>We are looking for a highly skilled and experienced Senior React Developer to join our dynamic team. The ideal candidate will be responsible for developing and implementing user interface components using React.js concepts and workflows such as Redux, Flux, and Webpack. You will ensure that these components and the overall application are robust and easy to maintain. You will coordinate with the rest of the team working on different layers of the infrastructure. A commitment to collaborative problem-solving, sophisticated design, and quality products is essential.</p>
                                        <p>Key Responsibilities:</p>
                                        <ul>
                                            <li>Develop new user-facing features using React.js</li>
                                            <li>Build reusable components and front-end libraries for future use</li>
                                            <li>Translate designs and wireframes into high-quality code</li>
                                            <li>Optimize components for maximum performance across a vast array of web-capable devices and browsers</li>
                                            <li>Collaborate with back-end developers and web designers to improve usability</li>
                                            <li>Ensure the technical feasibility of UI/UX designs</li>
                                            <li>Conduct code reviews and ensure adherence to best practices</li>
                                            <li>Stay up-to-date with emerging trends and technologies in front-end development</li>
                                            <li>Proficiency in designing interfaces and building clickable prototypes.</li>
                                        </ul>
                                        <p>Requirements</p>
                                        <ul>
                                            <li>Proven experience as a React Developer or in a similar role</li>
                                            <li>Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model</li>
                                            <li>Thorough understanding of React.js and its core principles</li>
                                            <li>Experience with popular React.js workflows (such as Flux or Redux)</li>
                                            <li>Familiarity with newer specifications of ECMAScript</li>
                                            <li>Experience with data structure libraries (e.g., Immutable.js)</li>
                                            <li>Knowledge of isomorphic React is a plus</li>
                                            <li>Familiarity with RESTful APIs</li>
                                            <li>Knowledge of modern authorization mechanisms, such as JSON Web Token</li>
                                            <li>Familiarity with modern front-end build pipelines and tools</li>
                                            <li>Experience with common front-end development tools such as Babel, Webpack, NPM, etc.</li>
                                            <li>Ability to understand business requirements and translate them into technical requirements</li>
                                            <li>A knack for benchmarking and optimization</li>
                                            <li>Familiarity with code versioning tools such as Git</li>
                                            <li>Excellent problem-solving skills and attention to detail</li>
                                            <li>Strong communication skills, both written and verbal</li>
                                            <li>Bachelor's degree in Computer Science, Engineering, or a related field, or equivalent experience</li>
                                        </ul>
                                        <p>Preferred Qualifications:</p>
                                        <ul>
                                            <li>Experience with server-side rendering</li>
                                            <li>Experience with TypeScript</li>
                                            <li>Familiarity with testing frameworks (e.g., Jest, Mocha)</li>
                                            <li>Knowledge of GraphQL</li>
                                        </ul>
                                        <p>
                                            NOTE:</p>
                                        <ul>
                                            <li>Please send resume to jobs@namfel.com after reviewing the
                                                job description above</li>

                                        </ul>

                                    </mdb-accordion-item-body>
                                </mdb-accordion-item>
                            </mdb-accordion>
                        </div>
                        <!--Grid column-->

                    </div>
                    <!--Grid row-->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Excerpt -->
                            <p>That’s all for this moment. But, don’t be upset, we’re posting new interesting jobs very
                                often!</p>

                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                </section>
                <!-- Section: Blog v.1 -->

            </div>
            <!--Grid column-->
        </div>
        <!--Grid row-->
    </div>
</main>