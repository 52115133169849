<!--Main Layout-->
<main>
    <div class="container">
        <!--Grid row-->
        <div class="row py-5">
            <!--Grid column-->
            <div class="col-md-12 mt-5">

                <!-- Section: Blog v.1 -->
                <section class="my-5">

                    <!-- Section heading -->
                    <h2 class="h1-responsive font-weight-bold text-center my-5">Privacy and policy</h2>

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>Our Commitment To You</strong></h3>
                            <!-- Excerpt -->
                            <p>NamFel Corp. (“NFC“) takes your privacy seriously. This privacy policy
                                (“Privacy Policy“) explains how your personal information is collected, used and
                                disclosed by NFC.</p>
                            <p>We are proud of our commitment to the protection of your personal information and to
                                transparency. If you have any questions about our Privacy Policy or any privacy related
                                concerns, please do not hesitate to contact a representative at
                                privacy@namfel.com.</p>
                            <p>Our Privacy Policy is based on Data Protection (privacy of Personal Information) Act.</p>
                            <p>In order to provide you with products and services, as well as to remain compliant with
                                applicable regulations and laws, NFC needs to know some of your personal information.
                            </p>
                            <p>You have a right to know how we collect, use and disclose your personal information and
                                to correct that information if it is wrong. You can rest assured that we will, to the
                                best of our ability, ensure that your personal information held by us remains accurate,
                                confidential and secure.</p>

                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col-xs-0 col-md-3">
                        </div>
                        <!-- Grid column -->

                        <!-- Grid column -->
                        <div class="col-xs-12 col-md-6">

                            <!-- Featured image -->
                            <div class="view overlay rounded z-depth-2">
                                <img class="img-fluid" src="./assets/privacy.png" alt="privacy">
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>
                        </div>
                        <!-- Grid column -->

                        <!-- Grid column -->
                        <div class="col-xs-0 col-md-3">
                        </div>

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>
                                    How We Collect And Use Your Personal Information</strong></h3>
                            <!-- Excerpt -->
                            <p>
                                NFC only collects, uses and discloses personal information for purposes that would be
                                considered reasonable in the circumstances and only such personal information as is
                                required for the purposes of providing our services. Personal information is collected
                                directly from the person concerned, their authorized representatives or from other
                                medical training, licensing, regulatory or credential verification organizations. We use
                                only fair and lawful methods to collect personal information.</p>

                            <p>Our use of personal information is limited to the purposes described in this Privacy
                                Policy or otherwise identified to you at the time of collection. We do not otherwise
                                sell, trade, barter, exchange or disclose for consideration any personal information
                                we obtain.</p>

                            <h5 class="font-weight-bold mb-1">NFC only uses your personal information for the following
                                purposes:</h5>

                            <ul>
                                <li>
                                    To process your registration application with us and determine your eligibility for
                                    our products and services;</li>
                                <li>
                                    To verify your medical declaration, your identity and other documentation you
                                    provide to us;</li>
                                <li>
                                    To offer products and services to you and to better understand your need for our
                                    products and services;</li>
                                <li>
                                    To process payment for our products and services;</li>
                                <li>
                                    To meet our legal and regulatory requirements; and</li>
                                <li>
                                    Other purposes consistent with these purposes.</li>
                            </ul>

                            <p>
                                Because we value your trust, we will only ask for personal information that we need and,
                                when we ask you for additional personal information, we will let you know why we need
                                it.</p>
                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>
                                    When And To Whom We May Disclose or Transfer Your Personal Information</strong></h3>
                            <!-- Excerpt -->
                            <p>

                                Where NFC transfers personal information to organizations that perform services on its
                                behalf, we will require those service providers to use such personal information solely
                                for the purposes of providing services to NFC or the person concerned and to have
                                appropriate safeguards for the protection of that personal information.</p>

                            <h5 class="font-weight-bold mb-1">
                                NFC may disclose your personal information to:</h5>

                            <ul>
                                <li>

                                    Individuals or organizations who are our service providers;</li>
                                <li>
                                    Individuals or organizations who are involved in maintaining, reviewing and
                                    developing our business systems, procedures and infrastructure including testing or
                                    upgrading our computer systems;</li>
                            </ul>

                            <p>
                                We may use service providers which need to process your personal information outside of
                                Canada. As a result, that country’s courts, governments or law enforcement agencies
                                could obtain disclosure of your information in accordance with that country’s laws.</p>

                            <p>
                                Please note that there are circumstances where NFC is permitted or obliged, under
                                applicable privacy legislation, to disclose personal information without consent. Such
                                circumstances include:</p>

                            <ul>
                                <li>
                                    Where required by law or by order or requirement of a court, administrative agency
                                    or governmental tribunal;</li>
                                <li>
                                    Where NFC reasonably believes that it is necessary to protect the rights, privacy,
                                    safety or property of an identifiable person or group;</li>
                                <li>
                                    Where it is necessary to establish or collect monies owing to NFC;</li>
                                <li>
                                    Where it is necessary to permit NFC to pursue legal remedies or limit any damages
                                    that we may sustain; or</li>
                                <li>
                                    In the event of a corporate amalgamation, reorganization, change of control or sale.
                                </li>
                            </ul>
                            <p>
                                A record is kept each time personal information is disclosed, noting the nature of the
                                disclosure, the date and the identity of the party to whom the disclosure was made,
                                provided that individual records of disclosure are not maintained for regular or routine
                                actions such as transfers of personal information to businesses acting as agents of NFC.
                            </p>
                            <h5 class="font-weight-bold mb-1">
                                Consent</h5>
                            <p>
                                By providing personal information to NFC you agree and consent that we may collect, use
                                and disclose your personal information in accordance with this Privacy Policy and any
                                documents that you have executed and/or delivered to NFC. Except as permitted under
                                applicable privacy legislation, this Privacy Policy or any documents that you have
                                executed and/or delivered to NFC, we won’t collect, use or disclose your personal
                                information without your consent. You can refuse to consent to our collection, use or
                                disclosure of personal information about you, or withdraw your consent to our further
                                collection, use or disclosure of personal information at any time by giving us
                                reasonable notice provided your refusal or withdrawal of consent does not limit our
                                ability to comply with applicable law with respect to the personal information in our
                                possession or limit any rights we have under this Privacy Policy or any documents that
                                you have executed and/or delivered to NFC. In certain cases, your refusal to consent
                                could prohibit your eligibility as a client of NFC.</p>
                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                    <!-- Grid row -->
                    <div class="row mb-5">

                        <!-- Grid column -->
                        <div class="col">

                            <!-- Post title -->
                            <h3 class="font-weight-bold mb-3"><strong>
                                    Unsubscribing</strong></h3>
                            <!-- Excerpt -->
                            <p>
                                If you do not want NFC to contact you or share your personal information with other NFC
                                partners, you may make the necessary arrangements through the offices of NFC, subject to
                                any rights we may have to disclose your personal information under applicable law, this
                                Privacy Policy or any documents that you have executed and/or delivered to NFC in
                                connection with this Privacy Policy.</p>
                            <p>
                                Even if you have opted out of receiving marketing communications from us, please be
                                aware that we may still contact you for other purposes. For example, we may contact you
                                to provide communications you have consented to receive, regarding the products or
                                services we provide to you, or if you contact us with an inquiry.</p>
                            <h5 class="font-weight-bold mb-1">
                                Changes To This Privacy Policy</h5>
                            <p>
                                From time to time NFC may make changes to this Privacy Policy to better reflect relevant
                                privacy legislation or best practices, in the normal course of business or to serve you
                                better. You understand and agree that it is your responsibility to periodically check
                                this Privacy Policy for any changes. In the event NFC makes a significant change to this
                                Privacy Policy, we will use reasonable efforts to draw the changes to your attention.
                            </p>

                            <h5 class="font-weight-bold mb-1">
                                The Accuracy Of Your Personal Information</h5>
                            <p>

                                While NFC tries to ensure that the personal information we hold about you is accurate,
                                complete and up-to-date, NFC does not routinely update your personal information, unless
                                required. Therefore, it is your responsibility to inform NFC promptly of any changes in
                                your personal information. NFC will, from time to time, verify the personal information
                                that you provide as part of its routine process of client verification or client
                                service. This may include verifying other elements of your personal information with
                                associated stakeholders.
                            </p>
                            <p>
                                If you believe that personal information in your records may be inaccurate, we make it
                                easy for you to access, verify and update it. If incorrect personal information has been
                                provided to third parties, we will convey the corrected information to them, if
                                necessary.</p>
                            <p>
                                If we do not agree to change your personal information, you may challenge our decision.
                                We will make a record of this challenge, and if necessary, disclose the challenge to
                                third parties who also possess the personal information.</p>

                            <h5 class="font-weight-bold mb-1">

                                Retention Of Your Personal Information</h5>
                            <p>
                                We keep your personal information only as long as it is required for the reasons it was
                                collected. The length of time NFC retains your personal information varies, depending on
                                the purpose for which it was collected and the nature of the information. This period
                                may extend beyond the end of your relationship with NFC but it will be only for so long
                                as it is necessary for us to have sufficient information to respond to any issues that
                                may arise at a later date. When your personal information is no longer required for our
                                purposes, procedures are in place to destroy, delete, erase or convert it into an
                                anonymous form.</p>

                            <p>Currently, the principal place in which NFC holds your personal information is Ontario,
                                Canada. We use accredited, secure, off-site digital storage facilities in Canada that do
                                not transmit or store data outside of Canada.</p>

                            <h5 class="font-weight-bold mb-1">
                                Access To Your Personal Information</h5>
                            <p>
                                NFC allows you to access and review your personal information held by us. We will
                                respond to a request for access within a reasonable time and generally no later than 30
                                days following the request.</p>
                            <p>
                                You can view your information through your online account. To review other personal
                                information that NFC has about you, simply make a written request to our representative
                                at privacy@namfel.com. The personal information will be obtained from your records at NFC
                                and will normally be provided within 30 days of your request. There may be a charge for
                                retrieving a complete list of personal information, in which case you will be notified
                                in advance and you may, at that time, withdraw your request.</p>
                            <p>
                                If you have a sensory disability, on request, we will give you access to your personal
                                information in an alternative format if available. If it is necessary and if we can
                                reasonably do so, we will convert the personal information into an alternative format.
                            </p>
                            <p>
                                Sometimes, NFC will not be able to provide you with your personal information that is
                                within its control. For example, NFC will not provide you with personal information in
                                its control if:</p>

                            <ul>
                                <li>


                                    It may harm or interfere with law enforcement activities and other investigative or
                                    regulatory functions of a body authorized by law to perform such functions;</li>
                                <li>

                                    It would disclose personal information, including opinions, about another individual
                                    or about a deceased individual;</li>
                                <li>
                                    It would disclose trade secrets or other business confidential or proprietary
                                    information of NFC;</li>
                                <li>
                                    It would interfere with contractual or other negotiations of NFC or a third party;
                                </li>
                                <li>
                                    It is not readily retrievable and the burden or cost of providing would be
                                    disproportionate to the nature or value of the information;</li>
                                <li>
                                    It does not exist, is not held, or cannot be found by NFC; or</li>
                                <li>
                                    It is subject to solicitor-client, litigation or other legal privilege.</li>
                            </ul>

                            <p>

                                If NFC denies your request for access to personal information, you will be told why,
                                unless we are prohibited by law from doing so. You may challenge that decision.</p>

                            <p>
                                Once you have the personal information, all you have to do is check it for accuracy and
                                let us know if there are any corrections required. If appropriate supporting
                                documentation is provided, we will correct our records or make note of any differences.
                                If personal information has been provided to third parties, we will convey the corrected
                                information or note any differences to them, if necessary.</p>


                            <h5 class="font-weight-bold mb-1">

                                The Security Of Your Personal Information</h5>
                            <p>

                                NFC, its staff and our partners are committed to protecting the security of the personal
                                information you choose to share with us.</p>
                            <p>
                                NFC has appointed a representative, who is also a member of senior management. Our
                                representative has the responsibility for coordinating overall privacy protection and
                                compliance with this Privacy Policy. You can contact NFC’s privacy representative at
                                privacy@namfel.com</p>
                            <p>
                                Ultimately, while NFC’s senior management is responsible for protecting the personal
                                information in its control, they delegate day-to-day responsibility to others within the
                                company. Every NFC employee, as a condition of employment, takes responsibility for
                                protecting the privacy, confidentiality and security of personal information. This
                                obligation remains in effect even after an employee leaves NFC. Suppliers of services to
                                NFC, such as e-commerce and credential verification firms, are required to sign
                                contracts obligating them to protect the privacy and confidentiality of personal
                                information provided to them to enable them to perform their functions.</p>
                            <p>
                                NFC also has extensive controls to maintain the security of their operations and
                                information systems, and these controls are tailored to reflect the sensitivity of the
                                personal information:</p>
                            <p>
                                Physical access to those areas where personal information is gathered, processed or
                                stored is restricted to authorized employees.</p>
                            <p>
                                Appropriate controls are in place over computer systems and data processing procedures
                                and these controls are reviewed on an ongoing basis to ensure compliance with our
                                security and privacy policies.</p>

                            <h5 class="font-weight-bold mb-1">
                                NFC destroys or erases any personal information when it is no longer needed.</h5>
                            <p>
                                When personal information is destroyed or erased, NFC takes care that there is no
                                unauthorized access.</p>
                            <h5 class="font-weight-bold mb-1">
                                Non-Personal Information</h5>
                            <p>
                                Non-personal information is information that cannot identify you personally, such as,
                                browser information and program data that records the website page choices you have
                                made.</p>
                            <p>
                                Our website may use ‘cookies’ to collect non-personal information about you, to help us
                                better deliver our products and services to you. A ‘cookie’ is a small text file sent to
                                your browser’s files for our reference. They allow our server computer to recognize you
                                when you return to our website and retain any preferences you have provided. If you do
                                not wish to receive cookies, consult your browser’s help menu in order to disable the
                                use of cookies.</p>
                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                </section>
                <!-- Section: Blog v.1 -->

            </div>
            <!--Grid column-->
        </div>
        <!--Grid row-->
    </div>
</main>