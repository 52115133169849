<!-- Main navigation -->
<header>
  <!--Navbar-->
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar">
    <mdb-navbar-brand>
      <a class="logo navbar-brand waves-light" mdbWavesEffect routerLink="/">
        <img id="main-logo" src="/assets/logo.png" alt="">
      </a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto mx-auto">
        <li class="nav-item waves-light px-2">
          <a class="nav-link font-weight-bold" routerLink="/" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                true}">
            Home
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item waves-light px-2">
          <a class="nav-link font-weight-bold" routerLink="/services" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact:
            true}">Services</a>
        </li>
        <li class="nav-item waves-light px-2">
          <a class="nav-link font-weight-bold" routerLink="/projects" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact:
            true}">Projects</a>
        </li>
        <li class="nav-item waves-light px-2">
          <a class="nav-link font-weight-bold" routerLink="/about" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact:
            true}">About</a>
        </li>
        <li class="nav-item waves-light px-2">
          <a class="nav-link font-weight-bold" routerLink="/contact" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact:
            true}">Contact</a>
        </li>
        <!-- <li class="nav-item waves-light px-2">
          <a class="nav-link font-weight-bold" (click)="frame.show()">
            <mdb-icon fas icon="lock"></mdb-icon> Login
          </a>
        </li> -->
      </ul>
    </links>
  </mdb-navbar>
</header>
<!-- Main navigation -->

<div mdbModal #frame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Sign in</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="md-form mb-5">
          <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
          <input type="email" id="defaultForm-email" [formControl]="loginFormModalEmail" class="form-control" mdbInput
            mdbValidate>
          <label for="defaultForm-email">Your email</label>
          <mdb-error *ngIf="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
            Input invalid
          </mdb-error>
          <mdb-success *ngIf="loginFormModalEmail.valid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
            Input
            valid
          </mdb-success>
        </div>

        <div class="md-form mb-4">
          <mdb-icon fas icon="lock" class="prefix grey-text"></mdb-icon>
          <input type="password" id="defaultForm-pass" [formControl]="loginFormModalPassword" class="form-control"
            mdbInput mdbValidate>
          <label for="defaultForm-pass">Your password</label>
          <mdb-error
            *ngIf="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
            Input invalid
          </mdb-error>
          <mdb-success
            *ngIf="loginFormModalPassword.valid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
            Input valid
          </mdb-success>
        </div>

      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="purple" class="waves-light" mdbWavesEffect>Login</button>
      </div>
    </div>
  </div>
</div>