<!--Main Layout-->
<main>
  <div class="container">
    <!--Grid row-->
    <div class="row py-5">
      <!--Grid column-->
      <div class="col-md-12 mt-5">
        <!--Projects section v.4-->
        <section class="text-center pb-3">

          <!--Section heading-->
          <h2 class="h1-responsive font-weight-bold text-center my-5">Our Best Projects</h2>
          <!--Section description-->
          <p class="pb-2 text-left">Our web or mobile apps, bespoke software and more, each of our
            projects has resulted in our partners achieving and exceeding their goals with the help of the
            latest technology. With over 500 projects and more than 300 native web and mobile applications,
            we have a lot of experience in software development for a variety of diverse industries, and we
            are always ready to share our expertise with you. Contact us today and get professional
            consultation about your ideas.
          </p>

          <p class="pb-3 text-left">We create engaging apps and user-friendly software solutions,
            with functionality as a cornerstone and design that reflects our client’s brand. Our clients’
            needs and business value shape each of our software development project more than anything else.
          </p>

          <!--Grid row-->
          <div class="row">

            <!--Grid column-->
            <div class="col-md-12 mb-4">
              <div class="card bg-dark text-white">
                <img src="./assets/home-security.jpg" class="card-img" alt="ecommerce"/>
                <div class="card-img-overlay">
                  <h6 class="purple-text">
                    <i class="fas fa-shield"></i>
                    <strong> Security and Automation</strong>
                  </h6>
                  <h3 class="py-3 font-weight-bold">
                    <strong>Falcon Alarm</strong>
                  </h3>
                  <p class="card-text">
                    Falcon Alarm specializes in smart home security, alarm monitoring, and video surveillance services. We leverage cutting-edge technology to design and install tailored, robust security solutions that ensure your home's safety. Our commitment to exceptional customer service includes 24/7 support and reliable, innovative security offerings. At Falcon Alarm, your peace of mind is our top priority. Secure your home smartly with us.
                  </p>
                </div>
              </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-12 mb-4">
              <div class="card bg-dark text-white">
                <img src="./assets/ecommerce.jpg" class="card-img" alt="ecommerce"/>
                <div class="card-img-overlay">
                  <h6 class="purple-text">
                    <i class="fas fa-shopping-cart"></i>
                    <strong> eCommerce</strong>
                  </h6>
                  <h3 class="py-3 font-weight-bold">
                    <strong>Store 24</strong>
                  </h3>
                  <p class="card-text">
                    Would like to start a successful retail business without having to make large
                      initial investments and pay high rent fees for the physical store space? Expand your new or
                      existing business through the Internet! Actually, Store 24 allows to run a fully
                      fledged store without having any inventory as well as promote your brand name and products
                      effectively.
                  </p>
                </div>
              </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-12 mb-4">
              <div class="card bg-dark text-white">
                <img src="./assets/blockchain-investment.jpg" class="card-img" alt="blockchain"/>
                <div class="card-img-overlay">
                  <h6 class="purple-text">
                    <i class="fab fa-ethereum"></i>
                    <strong> Blockchain</strong>
                  </h6>
                  <h3 class="py-3 font-weight-bold">
                    <strong>Treasury Work</strong>
                  </h3>
                  <p class="card-text">
                    Treasury Work is an enterprise asset tokenization engine built
                      on top of ethereum blockchain and hosted on the cloud.
                      The service can be used to implement blockchain investment websites or
                      platforms such as crowdfunding, tokenization investments, real estate investments,
                      stock market investments or any type of assets based investment.
                      The platform is built with microservices architecture hosted on azure and
                      can be easility integrated.
                  </p>
                </div>
              </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-12 mb-4">
              <div class="card bg-dark text-white">
                <img src="./assets/instagram.jpg" class="card-img" alt="Instagram"/>
                <div class="card-img-overlay">
                  <h6 class="purple-text">
                    <i class="fab fa-instagram"></i>
                    <strong> Social Network</strong>
                  </h6>
                  <h3 class="py-3 font-weight-bold">
                    <strong>Like Matrix</strong>
                  </h3>
                  <p class="card-text">
                    Like Matrix is a collection of Instagram tools at one place. You can access every tool for free. No need to pay for anything. At Like Matrix, you
                        can incrase your Instagram Followers, likes on post and get sophisticated
                        analytics reports. We tried to implement whats most usefull for a user and not just reinvent
                        the wheel.
                  </p>
                </div>
              </div>
            </div>
            <!--Grid column-->

          </div>
          <!--Grid row-->

        </section>
        <!--Projects section v.4-->
      </div>
      <!--Grid column-->
    </div>
    <!--Grid row-->
  </div>
</main>
